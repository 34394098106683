<template>
    <BasicCard title="My Account" class="background_accent">
        <p class="title text--primary mt-1">
            {{ $store.state.user.firstname }} {{ $store.state.user.lastname }}
        </p>
        <p class="subtitle-1">{{ $store.state.user.email }}</p>

        <v-row dense>
            <v-col cols="5">
                <span class="font-weight-bold">User ID</span>
            </v-col>
            <v-col cols="7">
                {{ $store.state.user.id }}
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">User Type</span>
            </v-col>
            <v-col cols="7">
                {{ $store.state.user.user_type }}
            </v-col>
        </v-row>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
export default {
    name: 'AccountCard',
    components: { BasicCard },
    props: {
        data: {
            type: Object,
            default: null
        }
    }
};
</script>
