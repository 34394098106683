<template>
    <vue-qr-code
        :value="value"
        :margin="margin"
        :scale="scale"
        error-correction-level="high"
    />
</template>
<script>
import VueQrCode from 'vue-qrcode'
export default {
    name: 'QRCode',
    components: { VueQrCode },
    props: {
        value: {
            type: String,
            default: null
        },
        margin: {
            type: Number,
            default: 4
        },
        scale: {
            type: Number,
            default: 6
        }
    }
};
</script>