<template>
    <ComplexDialog
        v-model="visible"
        :loading="loading || busy"
        title="Enable Two Factor Auth"
        persistent
        :max-width="450"
    >
        <v-window v-model="step" touchless>
            <v-window-item :value="1">
                <v-row dense>
                    <v-col cols="12">
                        <p>Two-factor authentication (2FA) is a security process which will require you to enter a One-Time Password (OTP) in addition to your password when logging in.</p>
                        <p>You can use any two-factor authentication app which supports Time-based One-Time Passwords (TOTP).</p>
                        <p style="margin-bottom:4px;"><strong>Some supported apps are listed below.</strong></p>
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <v-sheet outlined rounded elevation="2">
                            <div class="d-flex flex-row pa-2">
                                <div class="pr-2">
                                    <v-img
                                            :src="require('@/assets/img/3rd_party/google_authenticator.png')"
                                            max-width="48"
                                            max-height="48"
                                    />
                                </div>
                                <div>
                                    <div class="text--primary font-weight-bold">Google Authenticator</div>
                                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" class="pr-3">Google Play</a>
                                    <a href="https://apps.apple.com/au/app/google-authenticator/id388497605" target="_blank" class="pr-3">Apple App Store</a>
                                </div>
                            </div>
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" class="pa-2">
                        <v-sheet outlined rounded elevation="2">
                            <div class="d-flex flex-row pa-2">
                                <div class="pr-2">
                                    <v-img
                                            :src="require('@/assets/img/3rd_party/microsoft_authenticator.png')"
                                            max-width="48"
                                            max-height="48"
                                    />
                                </div>
                                <div>
                                    <div class="text--primary font-weight-bold">Microsoft Authenticator</div>
                                    <a href="https://play.google.com/store/apps/details?id=com.azure.authenticator" target="_blank" class="pr-3">Google Play</a>
                                    <a href="https://apps.apple.com/au/app/microsoft-authenticator/id983156458" target="_blank" class="pr-3">Apple App Store</a>
                                    <a href="https://docs.microsoft.com/en-us/azure/active-directory/user-help/user-help-auth-app-add-non-ms-account" target="_blank" class="pr-3">Setup Guide</a>
                                </div>
                            </div>
                        </v-sheet>
                    </v-col>

                    <v-col cols="12" class="pa-2">
                        <v-sheet outlined rounded elevation="2">
                            <div class="d-flex flex-row pa-2">
                                <div class="pr-2">
                                    <v-img
                                            :src="require('@/assets/img/3rd_party/authy.png')"
                                            max-width="48"
                                            max-height="48"
                                    />
                                </div>
                                <div>
                                    <div class="text--primary font-weight-bold">Authy</div>
                                    <a href="https://play.google.com/store/apps/details?id=com.authy.authy" target="_blank" class="pr-3">Google Play</a>
                                    <a href="https://itunes.apple.com/au/app/authy/id494168017" target="_blank" class="pr-3">Apple App Store</a>
                                    <a href="https://support.authy.com/hc/en-us/articles/360006303934-Add-a-New-Two-Factor-Authentication-2FA-Account-Token-in-the-Authy-App" target="_blank" class="pr-3">Setup Guide</a>
                                </div>
                            </div>
                        </v-sheet>
                    </v-col>
                    <v-col cols="12">
                      <p>When you are ready with your two-factor authentication app click next.</p>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item :value="2">
                <v-row dense v-if="!totp_secret && error">
                    <v-col cols="12" class="text-center">
                        <v-alert type="error">There was an error retrieving a new TOTP secret from the API.</v-alert>
                    </v-col>
                </v-row>
                <v-row dense v-if="!!totp_secret">
                    <v-col cols="12">
                        <p>Scan the below QR code using your two-factor authentication app on your mobile device.</p>
                    </v-col>
                    <v-col cols="12" class="text-center">
                        <QRCode v-if="totp_secret" :value="'otpauth://totp/Breeze%20Connect?secret=' + totp_secret" />
                        <p v-if="!showCode"><a @click="showCode=true">Click here if you are unable to scan the QR code</a></p>
                        <p v-if="showCode">TOTP secret: <span class="text--primary">{{totp_secret}}</span></p>
                    </v-col>
                    <v-col cols="12">
                        <v-form v-model="valid" v-on:submit.prevent="">
                            <p>Enter your one-time password below to complete the process.</p>
                            <v-alert type="error" v-if="invalidCode">The OTP code entered does not match the QR code.</v-alert>
                            <v-text-field
                                label="Current OTP"
                                v-model="otp"
                                outlined
                                :rules="rules"
                                autofocus
                                @keydown.enter="enter"
                            />
                        </v-form>
                    </v-col>
                </v-row>
            </v-window-item>
            <v-window-item :value="3">
                <v-row dense>
                    <v-col cols="12" class="text-center" v-if="busy">
                        <p class="py-16">Please wait...</p>
                    </v-col>
                    <v-col cols="12" class="text-center" v-if="!busy && error">
                        <v-icon size="120" color="error" class="mb-6"
                        >mdi-close-circle-outline</v-icon
                        >
                        <p>An error occurred while activating two-factor authentication for your account. If this problem persists, please contact Breeze Connect support.</p>
                    </v-col>
                    <v-col cols="12" class="text-center" v-if="!busy && success">
                        <v-icon size="120" color="success" class="mb-6"
                            >mdi-check-circle-outline</v-icon
                        >
                        <p>Two-factor authentication is now active for your account.</p>
                    </v-col>
                </v-row>
            </v-window-item>
        </v-window>
        <template v-slot:actions>
            <ButtonWithTooltip
                    key="backButton"
                    text="Back"
                    @click="step--"
                    v-if="step > 1"
                    :disabled="step===3"
                    flat
            />
            <v-spacer />
            <ButtonWithTooltip
                key="nextButton"
                :text="nextText"
                @click="next"
                color="primary"
                :disabled="step === 2 && !valid"
                flat
            />
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import QRCode from '../../pieces/Forms/QRCode';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'EnableTwoFactorDialog',
    components: { QRCode, ButtonWithTooltip, ComplexDialog },
    mixins: [apiMixin, dialogMixin],
    data: () => ({
        step: 1,
        otp: '',
        totp_secret: null,
        error: false,
        busy: false,
        showCode: false,
        valid: false,
        success: false,
        invalidCode: false,
        rules: [
            v => !!v || "OTP is required",
            v => /^\d{6}$/.test(v) || "OTP should be 6 digits"
        ]
    }),
    methods: {
        async getNewSecret() {
            this.busy = true;
            const response = await this.Api.send('post','auth/twofactor/secret');
            if (response.success) {
                this.totp_secret = response.data.secret;
            } else {
                this.error = true;
            }
            this.busy = false;
        },
        async activate2FA() {
            this.busy = true;
            const response = await this.Api.send('post','auth/twofactor/activate', { otp: this.otp, totp_secret: this.totp_secret });
            if (response.success) {
                this.success = true;
                this.step = 3;
                document.dispatchEvent(new CustomEvent('refreshRequested'));
            } else {
                if (response.errorDetail && response.errorDetail!=='Unable to validate OTP') {
                    this.step = 3;
                    this.error = true;
                } else {
                    this.invalidCode = true;
                }
            }
            this.busy = false;
        },
        next() {
            switch (this.step) {
                case 1:
                    this.step = 2;
                    break;
                case 2:
                    this.activate2FA();
                    break;
                case 3:
                    this.$emit('input', false);
                    break;
            }
        },
        enter() {
            if (this.valid) this.next();
        }
    },
    computed: {
        nextText() {
            return this.step===3 ? 'Finish' : 'Next';
        }
    },
    watch: {
        visible(value) {
            if (value) {
                this.getNewSecret();
            } else {
                this.step = 1;
                this.totp_secret = null;
                this.otp = '';
                this.error = false;
                this.showCode = false;
                this.invalidCode = false;
            }
        }
    }
};
</script>