<template>
    <ComplexDialog
        :loading="loading || busy"
        v-model="visible"
        :max-width="500"
        title="Change Password"
    >
      <v-alert v-if="error" transition="expand" type="error" text>{{error}}</v-alert>
      <v-alert type="info" text>Passwords should be between 8 and 32 characters long and contain at least 1 uppercase and 1 lowercase letter plus 1 number</v-alert>
      <v-text-field autofocus v-model="form.current_password" type="password" label="Current Password *" outlined />
      <PasswordField v-model="form.new_password" label="New Password *" outlined />
      <v-text-field v-model="form.confirm_password" type="password" label="Confirm New Password *" :disabled="!form.new_password" :error-messages="form.new_password!==form.confirm_password ? 'New passwords do not match' : undefined" outlined />
      <template v-slot:actions>
        <v-spacer/>
        <v-btn text @click="changePassword" color="primary" :disabled="!form.current_password || !form.new_password || form.new_password!==form.confirm_password">Apply</v-btn>
      </template>
    </ComplexDialog>
</template>

<script>
import apiMixin from '../../../mixins/apiMixin';
import dialogMixin from '@/mixins/dialogMixin';
import ComplexDialog from '@/components/dialogs/templates/ComplexDialog';
import PasswordField from '@/components/pieces/PasswordField';
export default {
    name: 'ChangePasswordDialog',
    mixins: [apiMixin, dialogMixin],
    components: { PasswordField, ComplexDialog },
    data: () => ({
      busy: false,
      error: null,
      form: {
        current_password: null,
        new_password: null,
        confirm_password: null
      }
    }),
    props: {
        data: {},
    },
    methods: {
        async changePassword() {
            this.busy = true;
            const response = await this.Api.send('post', 'auth/password', { current_password: this.form.current_password, new_password: this.form.new_password });
            if (response.success) {
                this.busy = false;
                this.visible = false;
                document.dispatchEvent(new CustomEvent('refreshRequested'));
            } else {
                this.error = response.data.description;
                this.busy = false;
            }
        }
    },
  watch: {
      visible(val) {
        if (!val) {
          this.form.current_password = null;
          this.form.new_password = null;
          this.form.confirm_password = null;
        }
      }
  }
};
</script>
