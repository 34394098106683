<template>
    <Toolbar :title="title" refresh-button />
</template>

<script>
import Toolbar from '../templates/Toolbar';
import NewCustomerDialog from '../../dialogs/Customer/NewCustomerDialog';
import partnerDataMixin from '../../../mixins/partnerDataMixin';
export default {
    name: 'PartnerToolbar',
    components: { Toolbar },
    mixins: [partnerDataMixin],
    computed: {
        title() {
            return (this.$vuetify.breakpoint.smAndUp ? (this.partnerData.companyname || 'Loading') + ' - ' : '') + (this.$route.meta.title || 'Partner');
        },
    },

};
</script>
