<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" sm="6">
                <AccountCard />
            </v-col>
            <v-col cols="12" sm="6">
                <AccountSecurityCard :loading="loading" />
            </v-col>
            <v-col cols="12" sm="6" v-if="$store.state.user.isAdmin">
                <AccountFreshdeskCard />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// @ is an alias to /src
//import ContactCard from '@/components/cards/ContactCard';
//import SupportCard from '@/components/cards/SupportCard';
import AccountSecurityCard from '../../components/cards/Account/AccountSecurityCard';
import AccountCard from '../../components/cards/Account/AccountCard';
import AccountFreshdeskCard from '../../components/cards/Account/AccountFreshdeskCard';
import apiMixin from '../../mixins/apiMixin';
import refreshListenerMixin from '../../mixins/refreshListenerMixin';
import store from '../../store';
//import AccountBalanceCard from '../components/cards/AccountBalanceCard';
export default {
    name: 'MyAccount',
    mixins: [apiMixin, refreshListenerMixin],
    data: () => ({
        loading: false
    }),
    components: {
        AccountFreshdeskCard,
        AccountCard,
        AccountSecurityCard,
        //AccountBalanceCard,
        //ContactCard,
        //SupportCard
    },
    methods: {
        async update() {
            this.loading = true;
            const response = await this.Api.send('post','auth/session');
            if (response.success) {
                this.$store.commit('session', response.data);
            }
            this.loading = false;
        },
    }
};
</script>
