<template>
    <BasicCard title="Account Security" :loading="loading" no-skeleton>
        <v-row dense class="text--primary">
            <v-col cols="6">
                <span class="font-weight-bold">Last Password Change</span>
            </v-col>
            <v-col cols="6">
                <LocalDateTime
                    :value="$store.state.user.password_updated_at"
                    relative
                />
            </v-col>
            <v-col cols="6">
                <span class="font-weight-bold">2FA Status</span>
            </v-col>
            <v-col cols="6">
                <span v-if="$store.state.user.twofactor_enabled"
                    ><IconWithTooltip
                        small
                        left
                        tooltip="Your account is protected by 2FA"
                        icon="mdi-check-circle"
                        color="success"
                    />Enabled</span
                >
                <span v-if="!$store.state.user.twofactor_enabled"
                    ><IconWithTooltip
                        small
                        left
                        tooltip="Enable 2FA to protect your account"
                    />Inactive</span
                >
            </v-col>
            <!-- v-col cols="6">
                <span class="font-weight-bold">Last 2FA Change</span>
            </v-col>
            <v-col cols="6">
                <LocalDateTime
                    :value="$store.state.user.twofactor_updated_at"
                    relative
                />
            </v-col -->
        </v-row>

        <template v-slot:actions>
            <ButtonWithTooltip
                v-if="$store.state.user.twofactor_enabled"
                icon="mdi-minus-circle"
                text="Disable 2FA"
                tooltip="Disable two-factor authentication"
                color="error"
                flat
                @click="showDisableTwoFactorDialog=true"
            />
            <ButtonWithTooltip
                v-if="!$store.state.user.twofactor_enabled"
                icon="mdi-shield-check"
                text="Enable 2FA"
                tooltip="Enable two-factor authentication"
                color="success"
                flat
                @click="showEnableTwoFactorDialog=true"
            />
            <ButtonWithTooltip
                icon="mdi-key"
                text="Change Password"
                tooltip="Change your password"
                color="primary"
                flat
                @click="showChangePasswordDialog=true"
            />
        </template>
        <EnableTwoFactorDialog v-model="showEnableTwoFactorDialog" />
        <DisableTwoFactorDialog v-model="showDisableTwoFactorDialog" />
        <ChangePasswordDialog v-model="showChangePasswordDialog" />
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import LocalDateTime from '../../pieces/LocalDateTime';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import IconWithTooltip from '../../pieces/IconWithTooltip';
import ChangePasswordDialog from '../../dialogs/User/ChangePasswordDialog';
import EnableTwoFactorDialog from '../../dialogs/User/EnableTwoFactorDialog';
import DisableTwoFactorDialog from '../../dialogs/User/DisableTwoFactorDialog';
export default {
    name: 'AccountSecurityCard',
    components: { DisableTwoFactorDialog, EnableTwoFactorDialog, ChangePasswordDialog, IconWithTooltip, ButtonWithTooltip, LocalDateTime, BasicCard },
    data: () => ({
        showChangePasswordDialog: false,
        showEnableTwoFactorDialog: false,
        showDisableTwoFactorDialog: false
    }),
    props: {
        loading: {
            type: Boolean,
            default: false
        }
    }
};
</script>
