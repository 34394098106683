<template>
    <ComplexDialog
        v-model="visible"
        :loading="loading || busy"
        title="Disable Two Factor Auth"
        persistent
        :max-width="450"
    >
        <v-window v-model="step" touchless>
            <v-window-item :value="1">
                <v-alert type="warning">Breeze Connect does NOT recommend disabling two-factor authentication for your account - this will leave your account less secure.</v-alert>
                <v-form v-model="valid" v-on:submit.prevent="">
                    <p>Please enter your current one-time password below to disable two-factor authentication.</p>
                    <v-alert type="error" v-if="invalidCode">The OTP you entered is not correct.</v-alert>
                    <v-text-field
                        label="Current OTP"
                        v-model="otp"
                        outlined
                        :rules="rules"
                        :autofocus="visible"
                        @keydown.enter="enter"
                    />
                </v-form>
            </v-window-item>
            <v-window-item :value="2">
                <v-row dense>
                    <v-col cols="12" class="text-center" v-if="busy">
                        <p class="py-12">Please wait...</p>
                    </v-col>
                    <v-col cols="12" class="text-center" v-if="!busy && error">
                        <v-icon size="120" color="error" class="mb-6"
                        >mdi-close-circle-outline</v-icon
                        >
                        <p>An error occurred while disabling two-factor authentication for your account. If this problem persists, please contact Breeze Connect support.</p>
                    </v-col>
                    <v-col cols="12" class="text-center" v-if="!busy && success">
                        <v-icon size="120" color="success" class="mb-6"
                            >mdi-check-circle-outline</v-icon
                        >
                        <p>Two-factor authentication is now disabled for your account.</p>
                    </v-col>
                </v-row>
            </v-window-item>
        </v-window>
        <template v-slot:actions>
            <v-spacer />
            <ButtonWithTooltip
                key="nextButton"
                :text="nextText"
                @click="next"
                color="primary"
                :disabled="!valid"
                flat
            />
        </template>
    </ComplexDialog>
</template>

<script>
import ComplexDialog from '../templates/ComplexDialog';
import dialogMixin from '../../../mixins/dialogMixin';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'DisableTwoFactorDialog',
    components: { ButtonWithTooltip, ComplexDialog },
    mixins: [apiMixin, dialogMixin],
    data: () => ({
        step: 1,
        otp: '',
        error: false,
        busy: false,
        invalidCode: false,
        valid: false,
        success: false,
        rules: [
            v => !!v || "OTP is required",
            v => /^\d{6}$/.test(v) || "OTP should be 6 digits"
        ]
    }),
    methods: {
        async disable2FA() {
            this.busy = true;
            const response = await this.Api.send('post','auth/twofactor/disable', { otp: this.otp });
            if (response.success) {
                this.success = true;
                this.step = 2;
                document.dispatchEvent(new CustomEvent('refreshRequested'));
            } else {
                if (response.errorDetail && response.errorDetail!=='Unable to validate OTP') {
                    this.step = 2;
                    this.error = true;
                } else {
                    this.invalidCode = true;
                }
            }
            this.busy = false;
        },
        next() {
            switch (this.step) {
                case 1:
                    this.disable2FA();
                    break;
                case 2:
                    this.$emit('input', false);
            }
        },
        enter() {
            if (this.valid) this.next();
        }
    },
    computed: {
        nextText() {
            return this.step===2 ? 'Finish' : 'Next';
        }
    },
    watch: {
        visible(value) {
            if (!value) {
                this.step = 1;
                this.otp = '';
                this.error = false;
                this.invalidCode = false;
            }
        }
    }
};
</script>