<template>
    <Toolbar
      :title="title"
      :bread-crumbs="breadCrumbs"
      refresh-button
    />
</template>

<script>
import Toolbar from '../templates/Toolbar';
export default {
    name: 'DefaultToolbar',
    components: { Toolbar },
    computed: {
        title() {
            return this.$route.meta.title || null;
        },
      //Not this logic is quite basic and was created for the Admin Report pages.
      //See the customerToolbar for more advanced logic
        breadCrumbs( ) {
          let addons = [...this.breadCrumbAddons];
          let ret = [];
          if(addons != null){
            ret = addons;
            ret.push({
              name:this.$route.name,
              label:this.$route.meta.title,
              params:this.$route.params,

            })
          }
          return ret;
        },
        breadCrumbAddons( ){
          return this.$store.state.data['breadCrumbsAddon'] ?? [];
        },
    },
};
</script>
