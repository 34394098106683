<template>
    <BasicCard
        title="Freshdesk Integration"
        v-bind:class="{
            'green accent-1': !$vuetify.theme.dark,
            'green darken-1': $vuetify.theme.dark,
        }"
        :loading="isLoading"
    >
        <template v-slot:feature>
            <v-img
                src="../../../assets/img/admin/logo/freshdesk.svg"
                contain
                :max-width="120"
            />
        </template>
        <v-row dense class="headline text--primary mb-2">
            <span v-if="$store.state.user.freshdesk_linked"
                >Freshdesk linked</span
            >
            <span v-if="!$store.state.user.freshdesk_linked"
                >Freshdesk not linked</span
            >
        </v-row>
        <v-row dense>
            <v-col cols="12" class="mb-2">
                <span v-if="$store.state.user.freshdesk_linked"
                    >A Freshdesk Agent has been linked to your account. You can
                    perform ticket operations via the portal.</span
                >
                <span v-if="!$store.state.user.freshdesk_linked"
                    >There is no Freshdesk Agent linked to your account. You
                    will be unable to perform ticket operations via the
                    portal.</span
                >
            </v-col>
        </v-row>

        <v-row dense v-if="data.id && data.id > 0">
            <v-col cols="4">
                <span class="font-weight-bold">Agent ID</span>
            </v-col>
            <v-col cols="8">
                {{ data.id }}
            </v-col>
            <v-col cols="4">
                <span class="font-weight-bold">Name</span>
            </v-col>
            <v-col cols="8">
                {{ data.contact.name }}
            </v-col>
            <v-col cols="4">
                <span class="font-weight-bold">Job Title</span>
            </v-col>
            <v-col cols="8">
                {{ data.contact.job_title }}
            </v-col>
            <v-col cols="4">
                <span class="font-weight-bold">Email</span>
            </v-col>
            <v-col cols="8">
                {{ data.contact.email }}
            </v-col>
            <v-col cols="4">
                <span class="font-weight-bold">Last Login</span>
            </v-col>
            <v-col cols="8">
                <LocalDateTime
                    :value="data.contact.last_login_at.replace('Z', '').replace('T', ' ')"
                    relative
                />
            </v-col>
        </v-row>

        <template v-slot:actions>
            <ButtonWithTooltip
                v-if="$store.state.user.freshdesk_linked"
                icon="mdi-link-off"
                text="Unlink"
                tooltip="Unlink Freshdesk agent account"
                color="error"
                flat
                disabled
            />
            <ButtonWithTooltip
                v-if="!$store.state.user.freshdesk_linked"
                icon="mdi-link"
                text="Link"
                tooltip="Link my Freshdesk agent account"
                color="success"
                flat
                disabled
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '../templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
import LocalDateTime from '../../pieces/LocalDateTime';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'AccountFreshdeskCard',
    components: { LocalDateTime, ButtonWithTooltip, BasicCard },
    mixins: [apiMixin],
    data: () => ({
        data: [],
        isLoading: false,
    }),
    methods: {
        async update() {
            this.isLoading = true;
            const response = await this.Api.send('post', 'tickets/myagentinfo', null, true);
            this.isLoading = false;
            if (response.success) {
                this.data = response.data.data;
            } else {
                this.data = [];
            }
        },
    },
    created() {
        this.update();
    },
};
</script>
